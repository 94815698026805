import NextHead from 'next/head';

export interface SeoHeadProps {
  canonical?: string;
  description?: string;
  image?: string;
  title?: string;
  url?: string;
}

export default function SeoHead({
  canonical,
  children,
  description = '用于 <Component> 时代的 CSS',
  image = 'https://www.styled-components.com/atom.png',
  title = 'styled-components 中文网',
  url = '',
}: React.PropsWithChildren<SeoHeadProps>) {
  const properCanonical = canonical || url;

  return (
    <NextHead>
      <title>{title}</title>

      <meta name="description" content={description} />

      {/* Open Graph */}
      <link itemProp="url" href="https://styled-components.com/" />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content="/atom.png" />

      <meta name="google-site-verification" content="lWntYW6AWVMcShSIWLmOzKr8Wyek2TR-zuQn6_XGu_c" />

      
      
      
      
      
      
      
      
      

      
      
      
      
      
      
      
      

      <link rel="canonical" href={`https://www.styled-components.com${properCanonical}`} />

      {children}

      <link rel="shortcut icon" href="/atom.png" />
      <link rel="icon" href="/atom.png" />
    </NextHead>
  );
}
